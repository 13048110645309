import React, {Component} from 'react';

const styles = {
    bar: {
        height: 6,
        backgroundColor: '#eee',
        marginTop: 10,
        marginBottom: 10,
    }
}

export default class ContentLoader extends Component {
    state = {
        opacity: 0,
        increase: true
    }

    interval = null

    componentDidMount() {
        this._tick()
    }

    componentWillUnmount() {
        clearTimeout(this.interval)
    }

    _tick = () => {
        if (this.state.opacity >= 1) {
            this.setState({ increase: false, opacity: 0.9 }, () => {
                this.interval = setTimeout(this._tick, 60)
            })
        }
        else if (this.state.opacity <= 0) {
            this.setState({ increase: true, opacity: 0.1 }, () => {
                this.interval = setTimeout(this._tick, 60)
            })
        }
        else {
            this.setState({ opacity: this.state.opacity + (this.state.increase ? 0.1 : 0) - (!this.state.increase ? 0.1 : 0) }, () => {
                this.interval = setTimeout(this._tick, 60)
            })
        }
    }

    render() {
        if (!this.props.display) return null
        return (
            <div style={{ marginTop: 20, marginBottom: 20, opacity: this.state.opacity }}>
                <div style={{...styles.bar, width: '30%'}}/>
                <div style={{...styles.bar, width: '80%'}}/>
                <div style={{...styles.bar, width: '50%'}}/>
                <div style={{...styles.bar, width: '65%'}}/>
            </div>
        )
    }
}

ContentLoader.defaultProps = {
    display: true
}