import React, {Component} from 'react';

import {Option, OptionGroup, Form, TextArea, Input, CheckBox, Radio, RadioGroup, FilePicker, DatePicker} from 'react-forms';

export default class SandboxPage extends Component
{
    state = {
        formData: null,
        text: 'azerty',
        password: '',
        remember: false,
        radio: 'world',
        option: 'everyone',
        options: ['hello', 'world'],
        file: null,
        date: null
    }

    render()
    {
        return (
            <div className="container">
                <h1>Sandbox</h1>
                <Form data={this.state.formData} toast>
                        <TextArea
                            value={this.state.text}
                            onChangeValue={(text) => this.setState({ text })}
                            id="text" name="text" label="Contenu" />

                    <Input
                        value={this.state.password} onChangeValue={(password) => this.setState({ password })}
                        placeholder="Hello" label="Mot de passe" type="password"/>

                    <DatePicker
                        value={this.state.date} onChangeValue={(date) => this.setState({ date })}
                        label="Date" type="date"/>

                    <FilePicker
                        value={this.state.file} onChangeValue={(file) => this.setState({ file })} label="Mon fichier" />

                    <CheckBox
                        name="remember" id="remember" value={this.state.remember}
                        onChangeValue={(remember) => this.setState({ remember })}>Remember me</CheckBox>

                    <RadioGroup
                        label={"Valeur: " + this.state.radio} name="radio" value={this.state.radio}
                        onChangeValue={(radio) => this.setState({ radio })}>
                        <Radio value="hello" id="hello">Hello</Radio>
                        <Radio value="world" id="world">World</Radio>
                    </RadioGroup>

                    <OptionGroup
                        label="Option unique" name="option" value={this.state.option}
                        onChangeValue={(option) => this.setState({ option })}>
                        <Option value="hello">Hello</Option>
                        <Option value="world">World</Option>
                        <Option value="everyone">Everyone</Option>
                    </OptionGroup>

                    <OptionGroup
                        multiple label="Options multiples" name="options" value={this.state.options}
                        onChangeValue={(options) => this.setState({ options })}>
                        <Option value="hello">Hello</Option>
                        <Option value="world">World</Option>
                        <Option value="everyone">Everyone</Option>
                    </OptionGroup>

                    <p>J'ai sélectionné :</p>
                    {this.state.options.length ? (
                        <ul>
                            {this.state.options.map((option) => <li>{option}</li>)}
                        </ul>
                    ) : <p className="text-muted">Rien de sélectionné !</p>}
                </Form>
            </div>
        )
    }
}
